<template>
  <b-form>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Hệ:"
          label-for="trainingSystem"
        >
          <v-select
            v-model="dataSendForm.trainingSystemId"
            :disabled="true"
            :options="trainingSystems"
            :reduce="option => option.value"
          />
        </b-form-group>

      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Khóa:"
          label-for="courses"
        >
          <v-select
            v-model="dataSendForm.courseId"
            :disabled="true"
            :options="courses"
            :reduce="option => option.value"
          />
        </b-form-group>

      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Kỳ học: "
          label-for="semester"
        >
          <v-select
            v-model="dataSendForm.courseSemesterId"
            :disabled="true"
            :options="courseSemesters"
            :reduce="option => option.value"
          />
        </b-form-group>
      </b-col>

      <!-- Created at -->
      <b-col cols="6">
        <b-form-group
          label="Thời gian bắt đầu đăng ký"
          label-for="start_date"
        >
          <flat-pickr
            v-model="dataSendForm.openRegisterAt"
            :config="config"
            placeholder="Thời gian mở đăng ký"
            name="start_at"
          />
        </b-form-group>
      </b-col>

      <!-- Created at -->
      <b-col cols="6">
        <b-form-group
          label="Thời gian kết thúc đăng ký"
          label-for="start_date"
        >
          <flat-pickr
            v-model="dataSendForm.closeRegisterAt"
            :config="config"
            placeholder="Thời gian đóng dăng ký"
            name="start_at"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <div class="float-left">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            @click="handleSaveData"
          >
            Lưu
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import {
  BButton, BCol, BForm, BFormGroup, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'
import flatPickr from 'vue-flatpickr-component'
import { vn } from 'flatpickr/dist/l10n/vn.js'
import { getUser } from '@/auth/utils'
import moment from 'moment'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  name: 'Index',
  components: {
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    dataSendForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: getUser(),
      selectedStatus: '',
      optionsStatus: [],
      optOrg: [],
      optDepartment: [],
      optSubject: [],
      table: {
        fields: [
          {
            field: 'nameDay', width: '80px', thClass: 'text-center', label: 'Thứ', sortable: false, type: 'string',
          },
          {
            field: 'turns', thClass: 'text-center', label: 'Tiết', sortable: false, type: 'string',
          },
        ],
        items: [],
      },

      config: {
        wrap: true,
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: vn,
        time_24hr: true,
        // minDate: 'today',
      },

    }
  },

  async created() {
    // init data FROM API
    await this.getInit()
    if (this.dataSendForm.isDelete) {
      this.dataSendForm.openRegisterAt = null
      this.dataSendForm.closeRegisterAt = null
    }
  },

  computed: {
    ...mapGetters({
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      trainingSystems: 'dropdown/trainingSystems',
    }),
    // calculate data from init data

  },
  methods: {
    ...mapActions({
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getTrainingSystems: 'dropdown/getTrainingSystems',
    }),
    // handle even

    handleSaveData(e) {
      //kiểm tra valid thời gia
      const openDate = moment(this.dataSendForm.openRegisterAt, 'DD/MM/YYYY');
      const closeDate = moment(this.dataSendForm.closeRegisterAt, 'DD/MM/YYYY');
      if (closeDate.isBefore(openDate)) {
        this.showToast("Thời gian đóng không được phép nhỏ hơn thời gian mở đăng ký học phần", 'XCircleIcon', 'danger')
        e.preventDefault()
        return;
      }
      this.$emit('saveDataForm', this.dataSendForm)
      e.preventDefault()
    },

    async getInit() {
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.user.orgId }),
        this.getCourses({ organizationId: this.user.orgId, trainingSystemId: this.dataSendForm.trainingSystemId }),
        this.getCourseSemesters({ organizationId: this.user.orgId, courseId: this.dataSendForm.courseId }),
      ])
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
